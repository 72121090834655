import React, { useState } from 'react';
import './App.css';
import underConstruction from './under-construction.png';

function App() {
  const [showPoem, setShowPoem] = useState(false);
  const buttonText = showPoem ? 'Terug naar de otters' : 'Tuuttuut gedicht voor jonne hiero';
  return (
    <div className="App">
      {!showPoem && <>
      <img className="image" src="https://source.unsplash.com/featured/?otter" alt=""/>
      <img className="overlay-image" src={underConstruction} alt=""/>
      </>}
      <button className="button" onClick={() => setShowPoem(!showPoem)}>{buttonText}</button>
      {showPoem && <div className="poem">
      <p>Lieve Jonne,</p>

      <p>Het is tijd voor enige bezinning, dit kun je niet blijven prammen.<br/>
De wereld staat in brand, maar jij bent immuun voor de vlammen.</p>

<p>De vuurzee in Australie kan gerekend worden tot de grootste van de eeuw,<br/>
Dit doet je vrijwel niks en je vraagt je af: Krijgen we dit jaar nog sneeuw?</p>

<p>Het wijdverspreiden van coofje is met carnaval een groot succes,<br/>
Maar het enige wat jou raakt is 1-0 eraf gaan tegen herácles hèracles</p>

<p>Bruno Bruins werkt zich een slag in de rondte en wordt onwel,<br/>
In dezelfde tijd ben jij geobsedeerd door dat ene videospel</p>

<p>Op vele digitale circuits waan je je Verstappen in de cockpit view,<br/>
Alles moet voor jou wijken, andere coureurs zijn Corona-jew.</p>

<p>Je favoriete huisgenoot heeft zijn morele kompas gelukkig wel gecheckt,<br/>
Ties grijpt in, ontneemt je de ps4, en vertrekt.</p>

<p>Dit was voor jou wederom nog geen wake-up call,<br/>
De wereld staat in brand, maar het interesseert je geen hol</p>

<p>Klappen voor de zorg vind je niks, die mensen zijn zwaar overschat,<br/>
Niemand klapt ook voor jou bij het bedenken van een briljant format.</p>

<p>In de media begin je steeds meer op de voorgrond te treden,<br/>
Maar bepaalde uitingen vallen niet chique aan te kleden.</p>

<p>Zonder blikken of blozen pak je credits voor een quote van goede vriend Jesse,<br/>
Daarnaast begin je te vertellen over natuurwijnen en hun finesse.</p>

<p>Rijpen op de schil, biodynamische vergisting en ander gezwam,<br/>
De wereld staat in brand, en jij begint een natuurwijn instagram</p>

<p>Jouw gebrek aan empathie maak je niet goed door het lezen van Bregman,<br/>
De meeste mensen deugen, maar ben jij wel een daarvan?</p>


<p>Meer mensen dan ooit brengen familieleden naar het graf,<br/>
Terwijl jij je verlekkert aan de dochter van Martien, blaf!</p>

<p>Ondernemers hebben het zwaar en sluiten hun bedrijven,<br/>
Jammer dan, jij negeert het advies in Nederland te blijven.</p>

<p>Jij maakt je druk om Ajax, want Labyad staat vaker dan gewenst basis,<br/>
Maar het overlijden van mensen deert je niet, zelfs niet als het Sinterklaas is.</p>

<p>Over de clash tussen Pieter Omtzigt en Hugo de Jonge kan ik kort zijn,<br/>
Hierin heb je de juiste keuze gemaakt, die Hugo is niet helemaal rein.</p>

<p>En op de valreep organiseer je ook nog deze mooie avond,<br/>
De immorele keuzes dit jaar zijn hiermee veredeld, terstond.</p>

<p>Je sleept de wijnen en het eten met je mee naar het fijne Utrecht,<br/>
De wereld staat in brand, maar wij hebben het lang niet slecht.</p>



<p>Veel voorspoed gewenst,</p>

        </div>}
    </div>
  );
}

export default App;
